@import "palettes";
@import "theme";
@import "mixins";

@import "theme";

$arrow: 10px;

.tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  max-width: calc(100vw - 40px);
  padding: 10px 15px;
  background: $black;
  color: $white;
  border-radius: 5px;
  font-family: $sans;
  font-size: 0.9rem;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  transition: opacity $fast;

  &[data-show="true"] {
    opacity: 1;
  }

  .tooltip_arrow {
    position: absolute;
    width: $arrow;
    height: $arrow;
  }

  .tooltip_arrow:after {
    content: "";
    position: absolute;
    width: $arrow;
    height: $arrow;
    background: $black;
    transform: rotate(45deg);
    z-index: -1;
  }

  &[data-popper-placement="top"] .tooltip_arrow {
    bottom: -0.5 * $arrow;
  }

  &[data-popper-placement="bottom"] .tooltip_arrow {
    top: -0.5 * $arrow;
  }

  &[data-popper-placement="left"] .tooltip_arrow {
    right: -0.5 * $arrow;
  }

  &[data-popper-placement="right"] .tooltip_arrow {
    left: -0.5 * $arrow;
  }
}
